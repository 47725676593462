import { WishListProduct } from './useWishList';

type BuiltWishList = {
    action: 'add' | 'remove';
    isSuccessfull: boolean;
    productData: WishListProduct;
    label?: string;
};

export const buildWishlist = ({ action, isSuccessfull, productData, label }: BuiltWishList) => {
    const { id, brand, name } = productData;
    return {
        eventAction: `Product ${action === 'add' ? 'added to' : 'removed from'} wishlist (${isSuccessfull ? 'success' : 'error'})`,
        eventLabel: isSuccessfull ? `${label} | ${id}` : label,
        productId: id,
        brand,
        name,
    };
};
