import styled from '@emotion/styled';
import { theme } from '~/theme';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { breakpoints } from '~/theme/breakpoints';

export const StyledBasketHeader = styled.div<{
    freeShipping: boolean;
    textColor?: string;
    backgroundColor?: string;
    textAlign?: string;
}>(({ theme, freeShipping, textColor, backgroundColor, textAlign }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: freeShipping ? 'center' : 'flex-start',
    margin: `0 ${theme.space[3]} ${theme.space[4]}`,
    maxWidth: '100%',
    borderRadius: theme.sizes.cornerRadius,
    padding: `${theme.space[2]}`,
    fontSize: `${theme.fontSizes['xs']}`,
    fontWeight: theme.fontWeights.medium,
    color: textColor ? textColor : theme.colors.black,
    backgroundColor: backgroundColor
        ? backgroundColor
        : freeShipping
          ? theme.colors.greenLight
          : theme.colors.grey20,
    ':has(:focus-visible)': {
        boxShadow: theme.shadows.accessibility,
    },

    [breakpoints.md]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: textAlign ? textAlign : freeShipping ? 'center' : 'space-between',
        margin: `0 0 ${theme.space[4]}`,
    },
}));

export const StyledButtonContinueShopping = styled(StyledResetButton)(() => ({
    marginTop: theme.space[2],
    fontSize: `${theme.fontSizes['xs']}`,
    textDecoration: 'underline',

    [breakpoints.md]: {
        marginTop: 0,
    },
}));
